<template>
  <div>
    <h3 class="empty-ph is-size-6">
      {{personalDataTitle}}
    </h3>
    <first-name-last-name
      ref="firstNameLastName"
      :first-name-place-holder="firstNamePlaceHolder"
      :first-name-initial="firstNameInitial"
      :last-name-place-holder="lastNamePlaceHolder"
      :last-name-initial="lastNameInitial"
      :error-first-name="errorFirstName"
      :error-last-name="errorLastName"
      @change-firstname="(value) => this.firstname = value"
      @change-lastname="(value) => this.lastname = value"
    />
    <div class="form-grouped d-flex">
      <div
        class="nice-select"
        id="phone-code-select"
        :class="codeSelectOpen ? 'open' : ''"
        @click="codeSelectOpen = !codeSelectOpen"
        style="min-width:auto;"
      >
        <span class="current">
          {{"+" + phoneCode}}
        </span>
        <ul class="list">
          <li
            class="option"
            :id="`phone-code-option-${id}`"
            :class="(phoneCode || '').toString() === item ? 'selected focus' : ''"
            v-for="(item, id) in phoneCodes"
            :key="id"
            @click="phoneCode = item"
          >{{"+" + item}}</li>
        </ul>
      </div>
      <div
        class="wet-input wet-input-with-label w-100"
        :class="$v.phone.$error ? 'wet-input-error' : ''"
      >
        <input
          id="wet-input-phone"
          type="tel"
          inputmode="tel"
          :placeholder="phonePlaceHolder"
          v-model="$v.phone.$model"
        >
        <label for="wet-input-phone">
          {{ phonePlaceHolder }}
        </label>
      </div>
    </div>
    <div v-if="$v.phone.$error" class="small text-primary mt-n2 mx-3">
      {{ phoneError }}
    </div>
  </div>
</template>

<script>
import {
  phone,
} from '@/common/validations';
import firstNameLastName from '@/components/vacation-buy/firstNameLastName.vue';
import { integer } from 'vuelidate/lib/validators';

export default {
  components: { firstNameLastName },
  props: {
    personalDataTitle: {
      type: String,
      required: true,
    },
    firstNamePlaceHolder: {
      type: String,
      required: true,
    },
    errorFirstName: {
      type: String,
      required: true,
    },
    errorLastName: {
      type: String,
      required: true,
    },
    lastNamePlaceHolder: {
      type: String,
      required: true,
    },
    phonePlaceHolder: {
      type: String,
      required: true,
    },
    phoneError: {
      type: String,
      required: true,
    },
    firstNameInitial: {
      type: String,
      required: true,
    },
    lastNameInitial: {
      type: String,
      required: true,
    },
    phoneInitial: {
      type: String,
      required: true,
    },
    phonePrefix: {
      type: integer,
      default: 49,
    },
  },
  mounted() {
    this.$emit('mounted');
  },
  watch: {
    firstNameInitial(newFirstNameInitial) {
      this.firstname = newFirstNameInitial;
    },
    lastNameInitial(newLastNameInitial) {
      this.lastname = newLastNameInitial;
    },
    firstname(newFirstname) {
      this.$emit('change-firstname', newFirstname);
    },
    lastname(newLastname) {
      this.$emit('change-lastname', newLastname);
    },
    phoneInitial(newPhoneInitial) {
      this.phone = newPhoneInitial;
    },
    phonePrefix(newPhonePrefix) {
      this.phoneCode = newPhonePrefix;
    },
    phoneCode(newPhoneCode) {
      this.$emit('change-phone-code', newPhoneCode);
    },
    phone(newPhone) {
      this.$emit('change-phone', newPhone);
    },
  },
  data() {
    return {
      firstname: this.firstNameInitial,
      lastname: this.lastNameInitial,
      phone: this.phoneInitial,
      codeSelectOpen: false,
      phoneCode: this.phonePrefix.toString(),
    };
  },
  computed: {
    phoneCodes() {
      return ['30', '31', '32', '33', '34', '351', '352', '353', '354', '351',
        '356', '357', '358', '359', '36', '370', '371', '372', '385', '386',
        '39', '40', '420', '421', '423', '43', '45', '46', '47', '48', '49'];
    },
  },
  validations() {
    return {
      phone,
    };
  },
};
</script>
