<template>
  <div class="row no-gutters">
    <div class="col-12 col-sm-6 pr-0 pr-sm-2">
      <div
        class="wet-input wet-input-with-label"
        :class="$v.firstname.$error ? 'wet-input-error' : ''"
      >
        <input
          id="wet-input-firstname"
          type="text"
          :placeholder="firstNamePlaceHolder"
          v-model.trim="$v.firstname.$model"
          :disabled="disableInput"
        >
        <label for="wet-input-firstname">
          {{ firstNamePlaceHolder }}
        </label>
      </div>
      <div v-if="$v.firstname.$error" class="small text-primary mt-n2 mb-3 mx-3">
        {{ errorFirstName }}
      </div>
    </div>
    <div class="col-12 col-sm-6 pl-0 pl-sm-2">
      <div
        class="wet-input wet-input-with-label"
        :class="$v.lastname.$error ? 'wet-input-error' : ''"
      >
        <input
          id="wet-input-lastname"
          type="text"
          :placeholder="lastNamePlaceHolder"
          v-model.trim="$v.lastname.$model"
          :disabled="disableInput"
        >
        <label for="wet-input-lastname">
          {{ lastNamePlaceHolder }}
        </label>
      </div>
      <div v-if="$v.lastname.$error" class="small text-primary mt-n2 mb-3 mx-3">
        {{ errorLastName }}
      </div>
    </div>
  </div>
</template>

<script>
import { firstname, lastname } from '@/common/validations';
import LogService from '@/services/LogService';

export default {
  name: 'firstNameLastName',
  emits: ['initial-error', 'change-firstname', 'change-lastname'],
  props: {
    firstNamePlaceHolder: {
      type: String,
      required: true,
    },
    errorFirstName: {
      type: String,
      required: true,
    },
    errorLastName: {
      type: String,
      required: true,
    },
    lastNamePlaceHolder: {
      type: String,
      required: true,
    },
    firstNameInitial: {
      type: String,
      required: true,
    },
    lastNameInitial: {
      type: String,
      required: true,
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    firstNameInitial(NewFirstNameInitial) {
      this.firstname = NewFirstNameInitial;
      this.checkInitialError();
    },
    lastNameInitial(NewLastNameInitial) {
      this.lastname = NewLastNameInitial;
      this.checkInitialError();
    },
    firstname(newFirstname) {
      this.$emit('change-firstname', newFirstname);
    },
    lastname(newLastname) {
      this.$emit('change-lastname', newLastname);
    },
  },
  methods: {
    checkInitialError() {
      this.$v.$touch();
      LogService.log('firstNameLastName, initial-error', this.$v.$error);
      this.$emit('initial-error', this.$v.$error);
    },
  },
  data() {
    return {
      firstname: this.firstNameInitial,
      lastname: this.lastNameInitial,
    };
  },
  mounted() {
    LogService.log('firstNameLastName created');
    if (this.disableInput) {
      this.checkInitialError();
    }
  },
  validations() {
    return {
      firstname,
      lastname,
    };
  },
};
</script>
